<template>
  <el-row class="table">
    <span class="title">课系费管理</span>
    <el-row class="row-bg">
      <el-input
        class="tb-input"
        v-model.trim="inputVal"
        placeholder="请输入课程名称"
        @keyup.enter.native="search">
      </el-input>
      <el-button type="primary" size="medium" @click="search()" class="tb-button">查 询</el-button>
      <el-button type="primary" size="medium" @click="reset()" class="tb-button">重 置</el-button>
    </el-row>
    <el-table :data="dataList" border fit highlight-current-row class="tb-list mt-15">
      <el-table-column align="left" label="课程标题" prop="title"></el-table-column>
      <el-table-column align="center" label="课系费" width="300">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-input v-model="scope.row.teacherFees" class="edit-input" size="small" @keyup.enter.native="confirmEdit(scope.row)"/>
            <el-button
              class="cancel-btn"
              size="small"
              type="warning"
              @click="cancelEdit(scope.row)"
            >取 消
            </el-button>
          </template>
          <span v-else>{{scope.row.teacherFees}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.edit"
            type="success"
            size="small"
            icon="el-icon-circle-check-outline"
            @click="confirmEdit(scope.row)"
          >保 存
          </el-button>
          <el-button
            v-else
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="scope.row.edit=!scope.row.edit"
          >编辑课时费
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
import { getCourseList, editTeacherFees } from '@/api/research/curriculum'

export default {
  data () {
    return {
      inputVal: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  created () {
    this.getCourseList()
  },
  methods: {
    async getCourseList () {
      const res = await getCourseList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        title: this.inputVal
      })
      this.dataList = res.body.list.map(v => {
        this.$set(v, 'edit', false)
        v.originalTitle = v.title
        return v
      })
      this.pagination.total = res.body.total
    },

    search () {
      this.pagination.currentPage = 1
      this.getCourseList()
    },

    reset () {
      this.inputVal = ''
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getCourseList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getCourseList()
    },

    cancelEdit (row) {
      row.title = row.originalTitle
      row.edit = false
    },

    async confirmEdit (row) {
      row.edit = false
      row.originalTitle = row.title
      await editTeacherFees({
        id: row.id,
        teacherFees: row.teacherFees
      })
      window.$msg('编辑成功')
      this.getCourseList()
    }
  }
}
</script>

<style lang="scss" scope>
@import "../../style/table.scss";
.edit-input {
  padding-right: 75px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
